import { ref, watch } from '@vue/composition-api'

export default function useProfilePasswordHandler(emit) {

  const initialPassState = {
    oldPass: {
      fieldType: 'password',
      toggleIcon: 'EyeOffIcon',
      text: ''
    },
    newPass: {
      fieldType: 'password',
      toggleIcon: 'EyeOffIcon',
      text: ''
    },
    newPass2: {
      fieldType: 'password',
      toggleIcon: 'EyeOffIcon',
      text: ''
    }
  }

  const passLocal = ref(JSON.parse(JSON.stringify(initialPassState)))

  const clearData = () => {
    passLocal.value = JSON.parse(JSON.stringify(initialPassState))
    // console.log('Blank Pass')
  }
 
  const oldPassToggle = () => {
    const closed = passLocal.value.oldPass.fieldType === 'password'
    if (closed) {
      passLocal.value.oldPass.fieldType = 'text'
      passLocal.value.oldPass.toggleIcon = 'EyeIcon'
    } else {
      passLocal.value.oldPass.fieldType = 'password'
      passLocal.value.oldPass.toggleIcon = 'EyeOffIcon'
    }
  }

  const newPassToggle = () => {
    const closed = passLocal.value.newPass.fieldType === 'password'
    if (closed) {
      passLocal.value.newPass.fieldType = 'text'
      passLocal.value.newPass.toggleIcon = 'EyeIcon'
    } else {
      passLocal.value.newPass.fieldType = 'password'
      passLocal.value.newPass.toggleIcon = 'EyeOffIcon'
    }
  }

  const newPass2Toggle = () => {
    const closed = passLocal.value.newPass2.fieldType === 'password'
    if (closed) {
      passLocal.value.newPass2.fieldType = 'text'
      passLocal.value.newPass2.toggleIcon = 'EyeIcon'
    } else {
      passLocal.value.newPass2.fieldType = 'password'
      passLocal.value.newPass2.toggleIcon = 'EyeOffIcon'
    }
  }

  const onSubmitPass = (a) => {
    const newPassword = JSON.parse(JSON.stringify(passLocal))
    const credentials = {
      oldPassword: newPassword.value.oldPass.text,
      newPassword: newPassword.value.newPass.text
    }
    emit('update-password', credentials)
  }

  return {
    // pass
    passLocal,
    oldPassToggle,
    newPassToggle,
    newPass2Toggle,
    onSubmitPass,
    clearData
  }
}
