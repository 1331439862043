<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Profile</span>
      </template>

      <account-setting-general
        :user-attributes="userAttributes"
        @update-settings="updateAttributes"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password
        @update-password="updatePassword"
      />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountSettingGeneral from './profile/ProfileGeneral.vue'
import AccountSettingPassword from './profile/ProfilePassword.vue'
import { ref, onMounted, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword
  },
  setup() {
    const toast = useToast()
    const userAttributes = ref({})

    onMounted(() => {
      fetchUserAttributes()
    })

    watch(store.getters['auth/user'], () => fetchUserAttributes())

    // Get User Attributes
    const fetchUserAttributes = () => {
      const userData = store.getters['auth/user']
      userAttributes.value = userData.attributes
    }

    // Update Attributes
    const updateAttributes = async val => {
      const userData = store.getters['auth/user']
      const prevData = userData.attributes

      // Check changes
      if (JSON.stringify(val) === JSON.stringify(prevData)) {
        console.log('No Changes Detected')
        return
      }

      try{
        let attributes = {
          name: val.name,
          'custom:ec_client_id': val['custom:ec_client_id'],
          'custom:ec_client_secret': val['custom:ec_client_secret'],
          'custom:ec_client_id_t': val['custom:ec_client_id_t'],
          'custom:ec_client_secret_t': val['custom:ec_client_secret_t'],
        }
        await store.dispatch('auth/updateAttributes', attributes )
        store.commit('auth/ATTRIBUTES', val)
        displaySucess('Attributes Updated')

      } catch (error) {
        displayError(`Error: updating the user attributes failed: ${error.message}`)
      }
    }

    // Update Password
    const updatePassword = async val => {
      try {
        await store.dispatch('auth/changePassword', val)
        displaySucess('Password Updated')
      } catch (error) {
        displayError(`Error: updating the user password failed: ${error.message}`)
      }
    }

    const displaySucess = title => {
      toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'EditIcon',
          variant: 'success'
        }
      })
    }

    const displayError = title => {
      toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
    }

    return {
      userAttributes,
      updateAttributes,
      updatePassword
    }
  }
}
</script>
