import { ref, watch } from '@vue/composition-api'

export default function useProfileGeneralHandler(props, emit) {

 
  // Attributes
  const userAttributesLocal = ref(JSON.parse(JSON.stringify(props.userAttributes.value)))

  const resetAttributesLocal = () => {
    userAttributesLocal.value = JSON.parse(JSON.stringify(props.userAttributes.value))
  }

  watch(props.userAttributes, () => resetAttributesLocal())

  const onSubmitAttributes = () => {
    const userAttributes = JSON.parse(JSON.stringify(userAttributesLocal))
    emit('update-settings', userAttributes.value)
  }
  
  return {
    // Attributes
    userAttributesLocal,
    resetAttributesLocal,
    onSubmitAttributes
  }
}
