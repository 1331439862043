<template>
  <b-card>
    <!-- form -->
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          ref="changePassForm"
          @submit.prevent="handleSubmit(onSubmitPass)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <!-- old password -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="oldPassword"
                rules="required"
              >
                <b-form-group
                  label="Old Password"
                  label-for="account-old-password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-old-password"
                      v-model="passLocal.oldPass.text"
                      name="old-password"
                      :type="passLocal.oldPass.fieldType"
                      :state="getValidationState(validationContext)"
                      placeholder="Old Password"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passLocal.oldPass.toggleIcon"
                        class="cursor-pointer"
                        @click="oldPassToggle"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!--/ old password -->
          </b-row>
          <b-row>
            <!-- new password -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="newPassword"
                rules="required|min:6|strong_password"
              >
                <b-form-group
                  label-for="account-new-password"
                  label="New Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="password"
                      v-model="passLocal.newPass.text"
                      :type="passLocal.newPass.fieldType"
                      :state="getValidationState(validationContext)"
                      name="password"
                      placeholder="New Password"
                      ref="password"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passLocal.newPass.toggleIcon"
                        class="cursor-pointer"
                        @click="newPassToggle"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="confirm"
                rules="required|password:@newPassword"
              >
                <b-form-group
                  label-for="account-retype-new-password"
                  label="Retype New Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="passLocal.newPass2.text"
                      :type="passLocal.newPass2.fieldType"
                      :state="getValidationState(validationContext)"
                      name="retype-password"
                      placeholder="New Password"
                      autocomplete="off"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passLocal.newPass2.toggleIcon"
                        class="cursor-pointer"
                        @click="newPass2Toggle"
                      />
                    </b-input-group-append>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!--/ retype password -->

            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                type="submit"
              >
                Save New Password
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="mt-1 mr-1"
                type="reset"
              >
                Clear
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import useProfilePasswordHandler from './useProfilePasswordHandler'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
})

extend('strong_password', {
  validate: value => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
    return strongRegex.test(value)
  },
  message: `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`
})

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      min
    }
  },
  setup(props, { emit }) {

    const {
      passLocal,
      oldPassToggle,
      newPassToggle,
      newPass2Toggle,
      onSubmitPass,
      clearData
    } = useProfilePasswordHandler(emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(clearData)

    return {
      passLocal,
      oldPassToggle,
      newPassToggle,
      newPass2Toggle,
      onSubmitPass,
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>
