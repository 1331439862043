<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="userAttributesLocal.name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="userAttributesLocal.email"
              name="email"
              placeholder="Email"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="PPAS Client ID - Production"
            label-for="ppas-client-id"
          >
            <b-form-input
              v-model="userAttributesLocal['custom:ec_client_id']"
              name="ppas-client-id"
              placeholder="PPAS Client ID"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="PPAS Client Secret - Production"
            label-for="ppas-client-secret"
          >
            <b-form-input
              v-model="userAttributesLocal['custom:ec_client_secret']"
              name="ppas-client-secret"
              placeholder="PPAS Client Secret"
            />
          </b-form-group>
        </b-col>

         <b-col sm="6">
          <b-form-group
            label="PPAS Client ID - Test"
            label-for="ppas-client-id-test"
          >
            <b-form-input
              v-model="userAttributesLocal['custom:ec_client_id_t']"
              name="ppas-client-id-test"
              placeholder="PPAS Client ID"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="PPAS Client Secret - Test"
            label-for="ppas-client-secret-test"
          >
            <b-form-input
              v-model="userAttributesLocal['custom:ec_client_secret_t']"
              name="ppas-client-secret-test"
              placeholder="PPAS Client Secret"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="onSubmitAttributes"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetAttributesLocal"
          >
            Undo Changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { toRefs } from '@vue/composition-api'
import useProfileGeneralHandler from './useProfileGeneralHandler'

export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BImg
  },
  directives: {
    Ripple
  },
  props: {
    userAttributes: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const {
      userAttributesLocal,
      resetAttributesLocal,
      onSubmitAttributes
    } = useProfileGeneralHandler(toRefs(props), emit)

    return {
      userAttributesLocal,
      resetAttributesLocal,
      onSubmitAttributes
    }
  }
}
</script>
